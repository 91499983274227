@import "bootstrap/dist/css/bootstrap.min.css";

:root {
  --primaryClr: #ffbd00;
  --secondaryClr: #222a34;
  --headClr: #101520;
  --bodyClr: #1a1e29;
  --whiteClr: #ffffff;
  --blackClr: #000000;
  --lightClr: rgba(255, 255, 255, 0.6);
  --siteRedClr: #af2f2f;
}
.light:root {
  --primaryClr: #ffbd00;
  --secondaryClr: #999;
  --headClr: rgba(0, 0, 0, 0.175);
  --bodyClr: #ddd;
  --whiteClr: #000000;
  --blackClr: #ffffff;
}

img {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 767.98px) {
  img {
    width: 100%;
    height: auto;
  }
  .RestrictionSection img {
    width: 220px !important;
    height: 220px !important;
  }
  .RestrictionSection .RestrictionContent p {
    font-size: 12px;
  }
  .RestrictionSection .RestrictionContent h4 {
    font-size: 18px;
  }
  .RestrictionSection {
    margin: 20px auto;
  }
  .header .headerRight .rightbarDropdown .dropdown-toggle {
    margin: 0 !important;
    padding: 0 !important;
  }
  .LoginPage.ForgotPage {
    min-width: 360px !important;
  }
  .LiveCasinoSection .casino-games .game-filter ul li a {
    font-size: 14px;
  }
  .LiveCasinoSection .BannerSection .all-images ul li a img {
    height: auto !important;
  }
  .header .headerRight .btn_signin,
  .header .headerRight .btn_signup {
    width: 66px !important;
  }
  .main .popular_game_sec .slick-slider .slick-arrow {
    display: none !important;
  }
  .main .popular_game_sec .head-popular,
  .main .casino_sec .heading h5 {
    margin: 4px 0 !important;
    white-space: nowrap;
  }
  .main .casino_sec .games a img.LiveCasinoImg {
    height: 80px !important;
    object-fit: cover !important;
  }
  .main .casino_sec .games a p,
  .main .casino_sec .games p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px !important;
    font-weight: 600 !important;
  }
  body .main .casino_sec .games.SportsbookSection .Sportsbookparent a img {
    height: 30px !important;
    width: 30px !important;
  }
  .main .casino_sec .games.SportsbookSection .Sportsbookparent {
    height: 50px !important;
    border: 1.5px solid transparent !important;
  }
  .main .popular_game_sec .slick-slider {
    padding: 10px 0 0 !important;
    margin: 8px 0 !important;
  }
  .main .casino_sec {
    padding: 4px 10px 10px !important;
  }
  .footer .quick-links {
    border-top: 1px solid #715a40;
    border-bottom: 1px solid #715a40;
    padding: 10px 0;
  }
  .footer .quick-links h5 {
    text-align: left !important;
    font-size: 20px !important;
    margin-bottom: 0 !important;
  }
  .footer .quick-links ul {
    flex-direction: column !important;
  }
  .footer .quick-links ul li {
    padding: 0 !important;
    line-height: 20px !important;
  }
  .footer .quick-links ul li a {
    font-size: 12px !important;
    line-height: 12px !important;
  }
  .footer .quick-links ul li:not(:last-child)::after {
    display: none !important;
  }
  .footer .contactus {
    margin-top: 15px !important;
  }
  .footer h5 {
    text-align: left !important;
  }
  .footer .contactus ul {
    margin: inherit !important;
  }
  .HpaySection img,
  .HpaySection canvas {
    width: 260px !important;
    height: 260px !important;
  }
  .HpaySection .HayQR img {
    width: 30px !important;
    height: 30px !important;
    right: 6px !important;
  }
  .MaintainSection {
    flex-wrap: wrap;
    padding: 20px !important;
    height: 100vh !important;
    justify-content: start !important;
  }
  .MaintainSection .MaintainSectionContent .MaintainSection__title,
  .MaintainSection .MaintainSectionContent p.backsoon {
    font-size: 30px !important;
    margin-bottom: 12px !important;
  }
  .MaintainSection .MaintainSectionContent p.maintainword {
    font-size: 35px !important;
    margin-bottom: 15px !important;
  }
  .MaintainSection .MaintainImg img {
    width: 250px !important;
  }
  .MaintainSection .MaintainSectionContent p.maintainword::before,
  .MaintainSection .MaintainSectionContent p.maintainword::after {
    display: none !important;
  }
  .MaintainSection .MaintainSectionContent,
  .MaintainSection .MaintainImg {
    width: 100% !important;
    text-align: center !important;
  }
  .sessionmarket .game-points ul li .status-btns .info-block:nth-child(2) {
    display: flex !important;
    width: 50% !important;
  }
  .gatewaySection {
    max-width: 340px !important;
  }

  .page-details .game-points ul li.bookmaker-new .status-btns .info-block {
    display: none !important;
  }
  .page-details
    .game-points
    ul
    li.bookmaker-new
    .status-btns
    .info-block:nth-child(4),
  .page-details
    .game-points
    ul
    li.bookmaker-new
    .status-btns
    .info-block:nth-child(5) {
    display: flex !important;
    width: 50% !important;
  }
  .live_tv_box,
  .iframe-div {
    height: 260px !important;
  }
  .live_tv_box .iframe-div iframe {
    height: 260px !important;
  }
  .live_tv_box .iframe-div {
    height: 300px;
  }
  .iframe-div,
  .live_tv_box {
    height: 200px !important;
  }
  .tabs-area .tab-content form .btn {
    margin-bottom: 12px !important;
    margin-right: 0 !important;
  }
}
@media only screen and (max-width: 480px) {
  .page-details .AllMarket_section .game-points ul li .team-name {
    display: block !important;
    width: calc(100% - 140px);
  }
}

* {
  padding: 0;
  margin: 0;
}

:root {
  --primaryClr: #169a85;
  --secondaryClr: #1c1d21;
  --whiteClr: #fff;
  --darkClr: #000;
  --grayClr: #a4a3a8;
  --lightGrayClr: #e4e4e4;
  --siteGreenClr: #508334;
  --siteYellowClr: #edbf2e;
}

/*=============== Common CSS ===============*/
html,
body {
  background: var(--whiteClr);
  font-size: 14px;
}
button:focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.form-control:focus,
.custom-select:focus {
  box-shadow: none;
}
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  transition: all 0.5s ease-in-out;
}
a:hover {
  text-decoration: none;
}
hr {
  border-color: var(--siteGreenClr);
}

/* betslip */
.betslip-wrapper {
  background: var(--headClr);
  display: flex;
  max-width: 350px;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  transition: 0.5s;
  right: 0;
  z-index: 999;
  box-shadow: 0 0px 8px rgba(255, 189, 0, 0.12);
}
.betslip-wrapper .betslip-head {
  border-top-left-radius: 0.5rem;
  box-shadow: 0 2px 18px rgb(0 0 0 / 25%);
  min-width: 100%;
  width: 100%;
  margin-left: 0 !important;
  right: 0;
  top: 0;
  z-index: 98;
}
.betslip-wrapper .betslip .betslip_close_btn {
  border-bottom: 1px solid var(--secondaryClr) !important;
  text-align: right !important;
}
.betslip-wrapper .closebtn {
  font-size: 18px;
  color: var(--whiteClr);
  margin-left: 0;
}
.betslipStart ul {
  border-bottom: 1px solid var(--secondaryClr);
  justify-content: center;
}
.betslipStart ul li a {
  background: transparent !important;
  color: var(--whiteClr) !important;
  font-weight: 600;
  text-align: center;
  border: 0 !important;
  padding: 0.5rem 1rem;
  font-size: 14px;
}
.betslipStart ul li a.active {
  color: var(--primaryClr) !important;
}
.betslipStart .betsTsbs ul.nav-tabs li {
  flex: 1;
}
.betslipStart .betslip-content {
  border-bottom: 0;
  padding: 1rem;
}
.betslipStart .betslip-content .match-name {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.betslipStart .betslip-content .match-name p {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0;
}
.betslipStart .betslip-content .match-name p .info {
  display: inline-block;
  margin-right: 0.75rem;
}
.betslipStart .betslip-content .match-name .betslip-close {
  background: #fff;
  border-radius: 50%;
  color: #080325;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  height: 16px;
  line-height: 13px;
  text-align: center;
  width: 16px;
}
.betslipStart .betslip-content .match-result {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}
.betslipStart .betslip-content .match-result .team-name {
  color: var(--primaryClr);
  font-size: 0.9rem;
  font-weight: 600;
}
.betslipStart .betslip-content .match-result .team-name span {
  color: #fff;
}
.betslipStart .betslip-content .match-result .odds {
  background: var(--bodyClr);
  border: 1px solid var(--secondaryClr);
  border-radius: 25px;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  height: 100%;
  min-width: 60px;
  padding: 0.35rem 0.5rem;
  text-align: center;
}
.sessionmarket .game-points ul li .status-btns .info-block:nth-child(2) {
  display: flex !important;
  width: 68px !important;
}
.betslipStart .betslip-content .stack-win-value {
  align-items: center;
  display: flex;
  padding-top: 1rem;
}
.betslipStart .betslip-content .stack-win-value .stack,
.betslipStart .betslip-content .stack-win-value .winning {
  flex: 1 1;
}
.betslipStart .betslip-content .stack-win-value .stack .form-control {
  -webkit-animation: glowingGreen 1s infinite;
  animation: glowingGreen 1s infinite;
  background: #220a49;
  border: 1px solid var(--secondaryClr);
  color: var(--whiteClr);
  max-width: 120px;
}
.betslipStart .betslip-content .stack-win-value .winning p {
  color: #999;
  margin-bottom: 0;
  margin-left: 1rem;
}
.betslipStart .betslip-content .stack-win-value .winning p span {
  color: var(--whiteClr);
}
.betslipStart .betslip-content .auto-stack {
  padding: 1rem 0;
  border-bottom: 1px solid var(--secondaryClr);
}
.betslipStart .betslip-content .auto-stack ul {
  align-items: center;
  display: flex;
  border-bottom: 0;
}
.betslipStart .betslip-content .auto-stack ul li {
  flex: 1 1;
  margin-right: 0.5rem;
}
.betslipStart .betslip-content .auto-stack ul li .stack-btn {
  background: var(--secondaryClr);
  color: #fff;
  padding: 0.25rem 0.5rem;
  width: 100%;
}
.betslipStart .betslip-content .bet-now {
  margin-top: 15px;
}
.betslipStart .betslip-content .bet-now .place-btn {
  background: var(--primaryClr);
  color: var(--blackClr);
  font-size: 1.15rem;
  font-weight: 600;
  width: 100%;
}
.betslipStart .open-bets {
  padding-top: 1rem;
}
.betslipStart .open-bets form {
  padding-left: 1rem;
}
.betslipStart .open-bets form .form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.betslipStart .open-bets form .form-check .form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.betslipStart .open-bets form label {
  color: #fff;
  cursor: pointer;
}
.betslipStart .open-bets .my-bets .bet-heading {
  background: #1d1a3e;
  padding: 0.5rem;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.betslipStart .open-bets .my-bets .bet-heading li:first-child {
  font-size: 0.9rem;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.betslipStart .open-bets .my-bets .bet-heading li {
  color: #ddd;
  font-size: 0.85rem;
  text-align: right;
  width: 25%;
}
.betslipStart .open-bets .my-bets body {
  height: auto !important;
  max-height: calc(100vh - 118px);
  overflow-y: auto;
}
.betslipStart .open_bets {
  padding-top: 1rem;
}
.react-tel-input .country-list .country {
  text-align: left;
}
.react-tel-input .country-list .country span {
  color: #000;
}

@media only screen and (max-width: 767px) {
  .LoginPage,
  .SignupPage {
    visibility: visible;
    min-width: 360px !important;
    max-width: 360px !important;
    padding: 10px 18px !important;
    margin-left: auto;
    margin-right: auto;
  }
  .LoginPage .offcanvas-header {
    padding: 20px 35px 20px 35px !important;
  }
  .SiginRegiaterBtn {
    flex-wrap: wrap !important;
  }
  body .LoginPage .offcanvas-body .SiginRegiaterBtn button {
    margin-top: 8px !important;
    margin-bottom: 0 !important;
    width: 100% !important;
    height: 40px !important;
    font-size: 16px !important;
  }
  .LoginPage .logo {
    margin: 0 auto 8px !important;
  }
  .footer .helpline {
    padding: 10px 8px !important;
    flex-wrap: wrap;
  }
  .footer .helpline a {
    margin-bottom: 10px;
  }
  .footer .helpline .facebook-footer a {
    margin-bottom: 0 !important;
  }
  .page-details
    .game-points
    ul
    li.bookmaker-new
    .status-btns
    .info-block:nth-child(4),
  .page-details
    .game-points
    ul
    li.bookmaker-new
    .status-btns
    .info-block:nth-child(5) {
    display: flex !important;
    width: 50% !important;
  }
  .main .payment-info .btn {
    font-size: 12px;
  }
  .main .payment-info .btn .exch-funds-form {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 10px;
    font-weight: bold;
  }
}
.Toastify {
  position: absolute;
  z-index: 9999999999999;
}
.iframe-div {
  height: 100vh;
  max-height: -webkit-fill-available;
}
.oddsChanged {
  background-color: rgb(252, 252, 78) !important;
}
